<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <div>
            <b-form class="d-flex" @submit.prevent="fetch(1)">
              <b-form-input class="mr-1" v-model="filter.q"
                            placeholder="Recherche par Site"/>
              <b-button type="submit" variant="primary">Recherche</b-button>
            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card

        no-body
    >

      <div class="m-2">
        <b-button
            variant="primary"
            :to="{ name: 'creer-site'}"
        >
          Créer site
        </b-button>
      </div>
      <b-table v-if="items"
               ref="selectableTable"
               :fields="fields"
               :items="items"

               responsive
               class="mb-0"

      >
        <template #cell(actions)="data">

          <b-button :to="{ name: 'editer-site', params : {id : data.item.id }}"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'" size="sm" variant="outline-primary">
            Editer
          </b-button>
        </template>
      </b-table>


    </b-card>
    <div class="mx-2 mb-2" v-if="pagination.total">
      <b-row>
        <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
     
          <b-pagination
              v-model="pagination.current_page"
              :total-rows="pagination.total"
              :per-page="pagination.per_page"
              first-number
              last-number
              @change="fetch"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </div>
</template>

<script>

import {
  BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody, BCard
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'


export default {
  components: {
    BCard,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      fields: ['id', {key: 'libelle', label: 'Site'}, {
        key: 'concession.libelle',
        label: 'Concession'
      }, {key: 'actions'}],      /* eslint-disable global-require */
      items: [],
      filter: {
        q: ''
      },
      is_loading : false,
      pagination: {},

    }
  },
  created() {
    this.fetch(1);
  },
  methods: {
    fetch(page) {
      let _this = this;
      _this.is_loading = true;
      this.$store.dispatch('siteModule/fetchAll', {
        page: page,
        ..._this.filter
      }).then(function (response) {
        _this.items = response.data;
        _this.pagination = response.meta;
        _this.is_loading = false;
      })
    }
  },
}
</script>
 
